<script setup lang="ts">
const props = defineProps<{
	modalStatus: FeedbackModalStatus
	onModalCloseWithoutFeedback: () => void
}>()

const i18n = useI18n()
const { isMobile } = useMobileViewport()
</script>

<template>
	<div class="flex w-full flex-col justify-between">
		<SpButton
			class="ml-auto"
			:icon-name="SpCommonIcon.Times"
			:size="isMobile ? SpButtonSize.DefaultIconOnly : SpButtonSize.SmallIconOnly"
			:type="SpButtonType.Ghost"
			@click="props.onModalCloseWithoutFeedback"
		/>
		<span class="heart-bounce mx-auto my-20 text-[58px]">💛</span>

		<div class="mb-14 flex flex-col gap-[15px] text-center">
			<SpText
				class="text-sky-600"
				:size="SpTextSize.HeadingLg01"
				:text="i18n.t(TranslationKeys['FEEDBACK.MODAL.GENERIC_THANK_YOU_TITLE'])"
			/>
			<SpText
				class="text-warm-grey-800"
				:size="SpTextSize.HeadingMd01"
				:text="i18n.t(TranslationKeys['FEEDBACK.MODAL.GENERIC_THANK_YOU_BODY'])"
			/>
		</div>
	</div>
</template>

<style scoped>
.heart-bounce {
	animation: bounce 2s infinite;
}

@keyframes bounce {
	0% {
		transform: scale(1);
	}
	30% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.5);
	}
	80% {
		transform: scale(1.5);
	}
	100% {
		transform: scale(1);
	}
}
</style>
